import React from 'react';
import { Link } from 'react-router-dom';
import { LuMoveRight } from 'react-icons/lu';
import frame from '../../../../assets/frame.png';
import adv_meeting from '../../../../assets/adv_meeting.jpeg';
import school_visit from '../../../../assets/school_visit.png';
import oct_intake from '../../../../assets/oct_intake.png';
import nov_agm from '../../../../assets/nov_agm.jpg';

const dummy_news = [
  {
    id: 1,
    category: 'NEWS',
    image: adv_meeting,
    date: 'Feb 14, 2024',
    title: '2023 December  Advisory meetings',
    description:
      'It was the First time to conduct meetings in English and most of the students were shy but left the meeting place positively and ready to practice English at school to be able to interact with ASEF staff and interns in April 2024.',
  },
  {
    id: 2,
    category: 'NEWS',
    image: school_visit,
    date: 'Feb 14, 2024',
    title: '2023 November School visits',
    description:
      'In total, 174 students were visited in 20 schools from November 07th to 23rd. The priority was new schools and those not visited in last academic year. We were happy to visit new schools like International Technical School of Kigali, MIPC, and Nyanza Hills Academy. Before using English in Advisory meetings, ASEF introduced it first in school visits. Students enjoyed the new challenge and said that they will be using English while talking to each other.',
  },
  {
    id: 3,
    category: 'NEWS',
    image: oct_intake,
    date: 'Feb 14, 2024',
    title: '2023 October New intake',
    description:
      '330 students entered the program - It is the first time to take such a large number of new students.',
  },
  {
    id: 4,
    category: 'NEWS',
    image: nov_agm,
    date: 'Feb 14, 2024',
    title: '2023 November AGM',
    description:
      'Kigali, 150 graduates attended ,Musanze , 87 graduates attended, Burera, 20 graduates attended,Nyanza, 52 graduates attended.',
  },
];

const AllNews = ({ setChosenNews }) => {
  return (
    <div className="w-full px-6 md:px-12 pb:6 lg:px-32 lg:pb-12 pt-6">
      <div
        className="bg-cover bg-center flex flex-col lg:flex-row items-center justify-between font-rubik max-h-[250px] lg:max-h-[150px] w-full px-12 py-6 rounded-2xl"
        style={{ backgroundImage: `url(${frame})` }}
      >
        <div className="flex flex-col gap-1">
          <h1 className="text-secondary font-bold text-2xl lg:text-4xl font-quicksand">
            Latest News
          </h1>
        </div>
        <p className=" w-full text-center lg:text-start lg:w-[40%]  text-white font-rubik text-base lg:text-lg">
          Discover our Latest News and Updates from our Organisation
        </p>
      </div>

      <div className="flex items-center justify-between my-8">
        <h1 className="font-quicksand font-bold text-2xl text-primary">
          LATEST NEWS AND UPDATES
        </h1>
      </div>
      <div className="flex flex-wrap gap-4 justify-start">
        {dummy_news?.map((news) => {
          return (
            <div className="p-6 flex flex-col bg-darkGrey max-w-[400px] rounded-lg">
              <div className="mb-2 rounded-lg overflow-hidden">
                <img src={news.image} className="w-full max-h-[195px]" alt="" />
              </div>
              <div className="flex items-center gap-4 mt-2">
                <p className="font-nunito font-bold text-secondary">NEWS</p>
                <p className="font-nunito">Apr 26, 2024</p>
              </div>
              <h4 className="font-bold text-xl my-2">
                {news.title.length > 40
                  ? news.title.slice(0, 40) + '...'
                  : news.title}
              </h4>

              <p className="text-[#6B6E71]">
                {news.description.length > 150
                  ? news.description.slice(0, 150) + '...'
                  : news.description}
              </p>

              <Link to={'/news/single'} className="w-fit mt-4">
                <div
                  className="flex gap-2 items-center w-fit cursor-pointer"
                  onClick={() => {
                    setChosenNews(news.id);
                  }}
                >
                  <p className="text-secondary font-rubik">Read more</p>
                  <LuMoveRight size={20} color="#FDB935" />
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AllNews;
