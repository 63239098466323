import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../components/Button';
import bags from '../../../../assets/bags.png';
import happy_kid from '../../../../assets/happy_kid.png';
import { CiPercent } from 'react-icons/ci';
import { PiBackpack } from 'react-icons/pi';
import { PiToiletPaperDuotone } from 'react-icons/pi';

const SponsorshipDetail = () => {
  return (
    <div
      id="sponsorship"
      className=" bg-darkGrey w-full py-12 px-6 md:px-12 lg:px-32 flex flex-col lg:flex-row justify-between gap-6"
    >
      <div className="flex flex-col lg:flex-row items-center lg:justify-between w-full h-full gap-12">
        <div className="flex flex-col w-full lg:w-1/2  gap-3">
          <div className="flex flex-col  items-start gap-2 mb-6">
            <div className="bg-secondary w-[60px] lg:w-[80px] h-[4px]"></div>
            <p className="text-center lg:text-start text-secondary text-2xl font-semibold">
              ASEF SPONSORSHIP
            </p>
          </div>
          <div className="">
            <h2 className="text-center lg:text-start text-primary font-quicksand font-bold mb-3 text-2xl lg:text-3xl">
              What does it mean when ASEF sponsors a student?
            </h2>
            <p className="font-rubik text-base text-center lg:text-start text-fontGrey">
              Once a student is selected to be sponsored by ASEF, he /she is
              provided with:
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col lg:flex-row items-start lg:items-center gap-6 lg:gap-12">
              <div className="flex items-center gap-4">
                <div className="rounded-xl bg-white flex items-center p-2">
                  <CiPercent size={30} color="#01A653" />
                </div>
                <p className="">100% of tuition fees</p>
              </div>

              <div className="flex items-center gap-4">
                <div className="rounded-xl bg-white flex items-center p-2">
                  <CiPercent size={30} color="#01A653" />
                </div>
                <p className="">Uniforms</p>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row items-start lg:items-center gap-6 lg:gap-12">
              <div className="flex items-center gap-4">
                <div className="rounded-xl bg-white flex items-center p-2">
                  <PiBackpack size={30} color="#01A653" />
                </div>
                <p className="">School Supplies</p>
              </div>

              <div className="flex items-center gap-4">
                <div className="rounded-xl bg-white flex items-center p-2">
                  <PiToiletPaperDuotone size={30} color="#01A653" />
                </div>
                <p className="">Hygiene Supplies</p>
              </div>
            </div>
          </div>

          <div className="mt-4 text-fontGrey">
            <p>
              The following fees are paid on a student's behalf to those
              attending government boarding schools. Fee structure is followed
              according to MINEDUC guidelines, which are currently:
            </p>

            <div className="flex flex-col lg:flex-row lg:items-center  gap-4 lg:gap-6 mt-4">
              <div className="flex flex-col">
                <p>School Tuition:</p>
                <p>85,000 Rwf.</p>
              </div>

              <div className="flex flex-col">
                <p>Additional:</p>
                <p>7,000 Rwf.</p>
              </div>

              <div className="flex flex-col">
                <p>Uniform S1 & S4:</p>
                <p>30,000 Rwf.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-6">
          <div className="rounded-3xl overflow-hidden">
            <img src={bags} alt="" />
          </div>
          <div className="rounded-3xl overflow-hidden">
            <img src={happy_kid} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponsorshipDetail;
