import React, { useEffect, useRef } from 'react';
import AboutUs from './about_pages/About';
import SponsorshipDetail from './about_pages/SponsorshipDetail';
import Founder from './about_pages/Founder';
import Team from './about_pages/Team';
import Video from './about_pages/Video';
import Partners from '../../partners/Partners';
import Footer from '../../../components/Footer';
import frame from '../../../assets/frame.png';

const About = ({ navigateToSection, setNavigateToSection }) => {
  const anchorRef = useRef(null);

  useEffect(() => {
    if (navigateToSection !== '') {
      anchorRef.current.click();
    }
  }, []);

  return (
    <div className="w-full h-full ">
      <div className="px-6 md:px-12 lg:px-32 py-6">
        <div
          className="bg-cover bg-center flex flex-col lg:flex-row items-center justify-between font-rubik max-h-[250px] lg:max-h-[150px] w-full px-12 py-6 rounded-2xl"
          style={{ backgroundImage: `url(${frame})` }}
        >
          <div className="flex flex-col gap-1">
            <h1 className="text-secondary font-bold text-2xl lg:text-4xl font-quicksand">
              About
            </h1>
            <h1 className="text-secondary text-4xl">ASEF</h1>
          </div>
          <p className=" w-full text-center lg:text-start lg:w-[40%]  text-white font-rubik text-base lg:text-lg">
            We appreciate your time dedicated to ASEF! Explore our mission and
            the essence of our organization.
          </p>
        </div>
      </div>

      <AboutUs />
      <SponsorshipDetail />
      <Founder />
      <Team />
      <Video />
      <Partners />
      <Footer />

      {/* Anchor tag with dynamic href set */}
      <a
        ref={anchorRef}
        href={navigateToSection}
        style={{ display: 'none' }}
      ></a>
    </div>
  );
};

export default About;
