import React from 'react';
import Marquee from 'react-fast-marquee';
import Carousel from '../../components/Carousel';
import gov from '../../assets/gov.png';
import reb from '../../assets/reb.png';
import wda from '../../assets/wda.png';
import rgb from '../../assets/rgb.png';

const ourPartners = [
  {
    image: gov,
    name: '',
  },
  {
    image: reb,
    name: '',
  },
  {
    image: wda,
    name: '',
  },
  {
    image: rgb,
    name: '',
  },
];

const Partners = () => {
  return (
    <div id="partners" className="w-full h-fit flex flex-col py-12 lg:py-32">
      <div className="flex items-center justify-center gap-2 mb-12">
        <div className="bg-secondary w-[50px] lg:w-[80px] h-[4px]"></div>
        <h1 className="text-secondary font-semibold font-rubik">
          OUR PARTNERS
        </h1>
        <div className="bg-secondary w-[50px] lg:w-[80px] h-[4px]"></div>
      </div>

      {/* <div className="hidden md:flex items-center justify-around gap-16 ">
        <div>
          <img src={gov} alt="GOV'T_OF_RWANDA" srcset="" />
        </div>
        <div>
          <img src={reb} alt="REB" srcset="" />
        </div>
        <div>
          <img src={wda} alt="WDA" srcset="" />
        </div>
        <div>
          <img src={rgb} alt="RGB" srcset="" />
        </div>
      </div> */}

      <div className="flex gap-6 items-center">
        <Marquee pauseOnHover speed={35} gradientWidth={20}>
          {ourPartners.map((partner, i) => {
            return (
              <div
                key={i}
                className="max-h-[150px] max-w-[320px] overflow-hidden object-contain mx-6 my-auto flex items-start justify-center"
              >
                <img
                  src={partner.image}
                  alt=""
                  className="mx-6 my-auto object-cover"
                />
              </div>
            );
          })}
        </Marquee>
      </div>
    </div>
  );
};

export default Partners;
