import React from 'react';
import about_one from '../../../../assets/about_one.png';
import amazed from '../../../../assets/amazed.png';
import frame from '../../../../assets/frame.png';

const About = () => {
  return (
    <div
      id="who-we-are"
      className="flex items-center h-fit xlg:h-full w-full px-6 md:px-12 lg:px-32"
    >
      <div className="flex flex-col-reverse lg:flex-row items-center lg:justify-between w-full h-full">
        <div className="relative w-fit mb-[65px] md:mb-[110px] mt-[20px] md:mt-[40px]">
          <div className="w-[150px] md:w-[300px] lg:w-[100%] overflow-hidden rounded-3xl">
            <img
              src={about_one}
              className="w-[150px] md:w-[300px] lg:w-[100%]"
              alt="ABOUT_US"
              srcset=""
            />
          </div>

          <div className="absolute bottom-[-50px] lg:bottom-[-100px] right-[-50px] lg:right-[-100px] rounded-3xl overflow-hidden">
            <img
              src={amazed}
              alt="ABOUT_US"
              className="w-[100px] md:w-[200px] lg:w-[100%]"
            />
          </div>
        </div>
        <div className="flex flex-col w-full lg:w-1/2 lg:ml-32 gap-3">
          <div className="flex flex-col lg:flex-row items-center gap-2">
            <div className="bg-secondary w-[60px] lg:w-[80px] h-[4px]"></div>
            <p className="text-center lg:text-start text-secondary text-lg">
              ABOUT ASEF
            </p>
          </div>
          <div className="">
            <h2 className="text-center lg:text-start text-primary font-quicksand font-bold mb-3 text-2xl lg:text-3xl">
              Who we are
            </h2>
            <p className="font-rubik text-base text-center lg:text-start">
              ASEF is a non-profit organization based in the United States of
              America that has been providing scholarships to vulnerable yet
              bright secondary school students in Rwanda since 2007. These
              students have the promising intellectual capacity yet are in great
              financial need. ASEF works as an INGO under the Rwanda Governance
              Board (RGB) and holds 501(c)3 status in the United States.
            </p>
          </div>

          <div className="">
            <h2 className="text-center lg:text-start text-primary font-quicksand font-bold mb-3 text-2xl lg:text-3xl">
              Our Mission
            </h2>
            <p className="font-rubik text-base text-center lg:text-start">
              Our mission is to make a better life possible through education
              for Rwandan children who do not have the financial means.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
