import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import bags from '../../assets/bags.png';
import happy_kid from '../../assets/happy_kid.png';
import { CiPercent } from 'react-icons/ci';
import { PiBackpack } from 'react-icons/pi';
import { RiShirtFill } from 'react-icons/ri';
import { PiToiletPaperDuotone } from 'react-icons/pi';

const Sponsorship = () => {
  return (
    <div className=" bg-darkGrey w-full py-12 px-6 md:px-12 lg:px-32 flex flex-col lg:flex-row justify-between gap-6">
      <div className="flex flex-col lg:flex-row items-center lg:justify-between w-full h-full gap-12">
        <div className="flex flex-col w-full lg:w-1/2  gap-3">
          <div className="flex flex-col  items-start gap-2 mb-6">
            <div className="bg-secondary w-[60px] lg:w-[80px] h-[4px]"></div>
            <p className="text-center lg:text-start text-secondary text-2xl font-semibold">
              ASEF SPONSORSHIP
            </p>
          </div>
          <div className="">
            <h2 className="text-center lg:text-start text-primary font-quicksand font-bold mb-3 text-2xl lg:text-3xl">
              What does it mean when ASEF sponsors a student?
            </h2>
            <p className="font-rubik text-base text-center lg:text-start">
              Once a student is selected to be sponsored by ASEF, he /she is
              provided with:
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col lg:flex-row items-start lg:items-center gap-6 lg:gap-12">
              <div className="flex items-center gap-4">
                <div className="rounded-xl bg-white flex items-center p-2">
                  <CiPercent size={30} color="#01A653" />
                </div>
                <p className="">100% of tuition fees</p>
              </div>

              <div className="flex items-center gap-4">
                <div className="rounded-xl bg-white flex items-center p-2">
                  <RiShirtFill size={30} color="#01A653" />
                </div>
                <p className="">Uniforms</p>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row items-start lg:items-center gap-6 lg:gap-12">
              <div className="flex items-center gap-4">
                <div className="rounded-xl bg-white flex items-center p-2">
                  <PiBackpack size={30} color="#01A653" />
                </div>
                <p className="lg:w-[147px]">School Supplies</p>
              </div>

              <div className="flex items-center gap-4">
                <div className="rounded-xl bg-white flex items-center p-2">
                  <PiToiletPaperDuotone size={30} color="#01A653" />
                </div>
                <p className="">Hygiene Supplies</p>
              </div>
            </div>
          </div>

          <Link to="/about-us" className="w-fit self-center lg:self-start">
            <Button
              type="button"
              content={
                <div className="flex items-center justify-center py-1 px-4">
                  <p className="text-white font-rubik font-normal">Read More</p>
                </div>
              }
              loading={false}
              btnColor="primary"
              Style={'text-white w-max flex mx-0 lg:mx-auto mt-12'}
            />
          </Link>
        </div>

        <div className="flex items-center gap-6">
          <div className="rounded-3xl overflow-hidden">
            <img src={bags} alt="" />
          </div>
          <div className="rounded-3xl overflow-hidden">
            <img src={happy_kid} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sponsorship;
