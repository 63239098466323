import React, { useState, useEffect } from 'react';
import { BiChevronRight } from 'react-icons/bi';

function Collapsible({ heading, body, key, Style }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={`rounded-lg cursor-pointer ${Style}`}
      key={key}
      onClick={handleToggle}
    >
      <div className="flex items-center gap-6 transition-all duration-300 ease-in-out w-full hover:text-primary">
        <BiChevronRight
          className={`transform duration-200 ${
            isExpanded ? '-rotate-90' : 'rotate-90'
          }`}
          size={30}
        />
        <div className="w-[90%] flex flex-row gap-4 items-center">
          {heading}
        </div>
      </div>

      <div className={`p-3 ${isExpanded ? '' : 'hidden'}`}>{body}</div>
    </div>
  );
}

export default Collapsible;
