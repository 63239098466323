import React, { useEffect, useRef } from 'react';
import OurPrograms from './programs_pages/OurPrograms';
import Footer from '../../../components/Footer';

const Programs = () => {
  return (
    <div>
      <OurPrograms />
      <Footer />
    </div>
  );
};

export default Programs;
