import React, { useState } from 'react';
import { PulseLoader } from 'react-spinners';

const Loader = (loader, setLoader) => {
  return (
    <div className="loader-container flex items-center justify-center w-full h-full my-auto">
      <PulseLoader color="#01A653" loading={loader} size={20} />
    </div>
  );
};

export default Loader;
