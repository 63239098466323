import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = ({
  slidesToShow,
  slidesToScroll,
  style,
  children,
  arrows = true,
  pauseOnHover = true,
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    initialSlide: 0,
    autoplay: true, // Set to true to enable autoplay
    autoplaySpeed: 5000, // Adjust the time between slides (in milliseconds)
    cssEase: 'linear',

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider
        {...settings}
        pauseOnHover={pauseOnHover}
        className={`${style}`}
        arrows={arrows}
      >
        {children}
      </Slider>
    </div>
  );
};

export default Carousel;
