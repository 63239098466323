import React from 'react';
import founder from '../../../../assets/founder.png';

const Founder = () => {
  return (
    <div
      id="founder"
      className="bg-white w-full h-fit lg:min-h-full px-6 md:px-12 py-6 lg:px-32 lg:py-12"
    >
      <div className="flex items-center gap-2 mb-4">
        <div className="bg-secondary w-[80px] h-[4px]"></div>
        <p className="text-secondary text-lg">OUR FOUNDER</p>
      </div>
      <div className="flex flex-col lg:flex-row items-start w-full h-full">
        <div className="flex flex-col h-full w-fit">
          <h1 className="font-bold text-2xl lg:text-4xl font-quicksand text-primary md:w-4/5 w-full mb-4 text-center md:text-start">
            JOURNEY THROUGH OUR ORGANISATION
          </h1>
          <div className="w-fit self-center lg:self-start flex flex-col">
            <div className="mb-2 w-fit rounded-3xl overflow-hidden max-h-[400] max-w-[515px]">
              <img src={founder} alt="FOUNDER" />
            </div>

            <p className="text-center font-quicksand font-bold">
              ASEF founder Erik With President Barack Obama
            </p>
          </div>
        </div>
        <div className="flex flex-col w-full lg:w-1/2 h-full gap-6 font-rubik text-[#575757] lg:text-lg mt-6 lg:mt-0">
          <p className="">
            In 2007, ASEF Founder and President Erik Schmollinger first came to
            Rwanda as a tourist. He was immediately struck by the good nature of
            the Rwandese people. He was traveling throughout East Africa and to
            hear him tell it, “It was like a breath of fresh air” when he
            crossed into Rwanda.
          </p>

          <p>
            There was something tangibly different. Given the troubled past,
            Erik could not believe that these were the same people that had
            suffered so greatly just over a decade before. The joy and
            incredible spirit of Rwandans inspired him to want to share in the
            healing and the light present here in Rwanda. He extended his stay
            and spent a few weeks volunteering in an orphanage in Musanze.
            During this time, he met numerous children who were in desperate
            need of help.
          </p>

          <p>
            He decided if he could help a few children go to school, he could
            somehow join in creating a brighter future for all of Rwanda. That
            first year he sponsored 3 students to go to secondary schools. He
            came back the following year and added 5 more students.
          </p>
          <p>
            He has expanded every year since then to sponsor more and more
            students. This year, ASEF is providing tuition and supplies to over
            1,200 students in Rwanda.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Founder;
