import React from 'react';
import Button from '../../../../components/Button';
import { Link } from 'react-router-dom';
import frame from '../../../../assets/frame.png';

const OurPrograms = () => {
  return (
    <div className="w-full flex flex-col px-6 md:px-12 py-6 lg:px-32 lg:py-12 gap-16 lg:gap-32">
      <div
        className="bg-cover bg-center flex flex-col lg:flex-row items-center justify-between font-rubik max-h-[250px] lg:h-[150px] w-full px-12 py-6 rounded-2xl"
        style={{ backgroundImage: `url(${frame})` }}
      >
        <div className="flex flex-col gap-1">
          <h1 className="text-secondary font-bold text-2xl lg:text-4xl font-quicksand">
            Our Programs
          </h1>
        </div>
        <p className=" w-full text-center lg:text-start lg:w-[40%]  text-white font-rubik text-base lg:text-lg">
          Welcome again! Navigate our Programs as well as their impact to our
          beneficiaries.
        </p>
      </div>

      <div
        id="in-take"
        className="flex flex-col lg:flex-row items-start gap-6 justify-center"
      >
        <div className="flex flex-col w-full lg:w-1/2 gap-6">
          <h1 className="font-quicksand font-bold text-3xl text-primary">
            NEW INTAKE
          </h1>
          <p className="font-rubik text-[#464646]">
            ASEF collaborates with the districts and sectors authorities
            typically District Education officers (DEOs), Vice Mayor Social
            Affairs, Director of Social Affairs, JADF Officers, Sector Education
            Inspectors (SEIs) to identify the vulnerable and bright students.
            Not always ASEF works with these administrators; it depends on the
            district and the people last worked with.
          </p>
          <p className="font-rubik text-[#464646]">
            Every time before new intakes, ASEF plans a meeting with district
            and sector authorities to be in line for the selection as the
            opportunities are only for students who meet all criteria listed as
            below on ...
          </p>

          <Link to={'/programs/new-intake'} className="w-fit">
            <Button
              type="button"
              content={
                <div className="flex items-center justify-center px-2">
                  <p className="text-white font-rubik font-normal">READ MORE</p>
                </div>
              }
              loading={false}
              btnColor="secondary"
              Style={'text-white w-max ml-0'}
            />
          </Link>
        </div>

        <div className="w-full lg:w-1/2 bg-cover">
          <div className="w-full rounded-lg lg:rounded-3xl overflow-auto">
            <iframe
              src="https://www.youtube.com/embed/zEQTLpC-EVw?si=eVgXtWZVd-0SvBZP"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="w-full aspect-video my-auto"
            ></iframe>
          </div>
        </div>
      </div>

      <div
        id="#advisory"
        className="flex flex-col-reverse lg:flex-row items-start gap-6 justify-center"
      >
        <div className="w-full lg:w-1/2 bg-cover">
          <div className="w-full rounded-lg lg:rounded-3xl overflow-auto">
            <iframe
              src="https://www.youtube.com/embed/0d0vav6kUa8?si=IumcfDBc_62A08dU"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="w-full aspect-video my-auto"
            ></iframe>
          </div>
        </div>
        <div className="flex flex-col lg:w-1/2 gap-6">
          <h1 className="font-quicksand font-bold text-3xl text-primary">
            ADVISORY MEETINGS
          </h1>
          <p className="font-rubik text-[#464646]">
            ASEF does not believe that paying tuition fees and providing school
            supplies to our students is enough for them to succeed in life. We
            think that they need more than that and we are more than ready to do
            our best to help them in various ways.
          </p>
          <p className="font-rubik text-[#464646]">
            It is in this context that ASEF organizes students' advisory
            meetings that take place at the end of each semester in our
            beneficiaries' district of residence to review and evaluate their
            behavior and school performance.
          </p>
          <Link to={'/programs/advisory-meetings'} className="w-fit">
            <Button
              type="button"
              content={
                <div className="flex items-center justify-center px-2">
                  <p className="text-white font-rubik font-normal">READ MORE</p>
                </div>
              }
              loading={false}
              btnColor="secondary"
              Style={'text-white w-max ml-0'}
            />
          </Link>
        </div>
      </div>

      <div
        id="supplies"
        className="flex flex-col lg:flex-row items-start gap-6 justify-center"
      >
        <div className="flex flex-col lg:w-1/2 gap-6">
          <h1 className="font-quicksand font-bold text-3xl text-primary">
            SUPPLY DISTRIBUTION
          </h1>
          <p className="font-rubik text-[#464646]">
            ASEF thought about giving the supplies to the students as the basic
            demands in their studies that come up to the tuition fees because
            the schools require the students to bring them each term. Most of
            ASEF beneficiaries are from rural areas where their families do not
            take hygiene as the priority due to the supplies are too expensive
            and they can't afford them.
          </p>

          <Link to={'/programs/supplies'} className="w-fit">
            <Button
              type="button"
              content={
                <div className="flex items-center justify-center px-2">
                  <p className="text-white font-rubik font-normal">READ MORE</p>
                </div>
              }
              loading={false}
              btnColor="secondary"
              Style={'text-white w-max ml-0'}
            />
          </Link>
        </div>
        <div className="w-full lg:w-1/2 bg-cover">
          <div className="w-full rounded-lg lg:rounded-3xl overflow-auto">
            <iframe
              src="https://www.youtube.com/embed/zEQTLpC-EVw?si=eVgXtWZVd-0SvBZP"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="w-full aspect-video my-auto"
            ></iframe>
          </div>
        </div>
      </div>

      <div
        id="graduate"
        className="flex flex-col-reverse lg:flex-row items-start gap-6 justify-center"
      >
        <div className="w-full lg:w-1/2 bg-cover">
          <div className="w-full rounded-lg lg:rounded-3xl overflow-auto">
            <iframe
              src="https://www.youtube.com/embed/SeMsSkCUbjs?si=MOa3zb9qSiIQArZ7"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="w-full aspect-video my-auto"
            ></iframe>
          </div>
        </div>
        <div className="flex flex-col lg:w-1/2 gap-6">
          <h1 className="font-quicksand font-bold text-3xl text-primary">
            GRADUATE MEETINGS
          </h1>
          <p className="font-rubik text-[#464646]">
            ASEF beneficiaries complete their scholarship with ASEF at the end
            of senior 6. Those who finish senior 6, are called ASEF graduates
            because they are no longer supported by ASEF. Most of them continue
            to study at different universities and colleges. The big part
            continues their studies in Rwanda, some go to UR, IPRCs, Kepler,
            ALU, UGHE, or RICA. But we have others who apply for abroad
            scholarships and study in the USA, Germany and Asia.
          </p>

          <Link to={'/programs/graduate-meetings'} className="w-fit">
            <Button
              type="button"
              content={
                <div className="flex items-center justify-center px-2">
                  <p className="text-white font-rubik font-normal">READ MORE</p>
                </div>
              }
              loading={false}
              btnColor="secondary"
              Style={'text-white w-max ml-0'}
            />
          </Link>
        </div>
      </div>

      <div
        id="jadf"
        className="flex flex-col lg:flex-row items-start gap-6 justify-center"
      >
        <div className="flex flex-col w-full lg:w-1/2 gap-6">
          <h1 className="font-quicksand font-bold text-3xl text-primary">
            JADF ACTIVITIES
          </h1>
          <p className="font-rubik text-[#464646]">
            The Joint Action Development Forum (JADF) is a multi-stakeholder
            platform comprised of representatives from the public sector,
            private sector and civil society. It was put in place to facilitate
            and promote full participation of citizens in the decentralized and
            participatory governance and improve service provision processes.
          </p>

          <p className="font-rubik text-[#464646]">
            ASEF is a member of JADF together with other institutions and
            organizations operating at District level including public,
            private,...
          </p>

          <Link to={'/programs/jadf'} className="w-fit">
            <Button
              type="button"
              content={
                <div className="flex items-center justify-center px-2">
                  <p className="text-white font-rubik font-normal">READ MORE</p>
                </div>
              }
              loading={false}
              btnColor="secondary"
              Style={'text-white w-max ml-0'}
            />
          </Link>
        </div>

        <div className="w-full lg:w-1/2 bg-cover">
          <div className="w-full rounded-lg lg:rounded-3xl overflow-auto">
            <iframe
              src="https://www.youtube.com/embed/QS9sCMrZ_-w?si=YJ463AcLVO9cokdn"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="w-full aspect-video my-auto"
            ></iframe>
          </div>
        </div>
      </div>

      <div
        id="visits"
        className="flex flex-col-reverse lg:flex-row items-start gap-6 justify-center mb-12"
      >
        <div className="w-full lg:w-1/2 bg-cover">
          <div className="w-full rounded-lg lg:rounded-3xl overflow-auto">
            <iframe
              src="https://www.youtube.com/embed/kBr2RfXPoQE?si=4W3r8h9Nip6C88mj"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="w-full aspect-video my-auto"
            ></iframe>
          </div>
        </div>

        <div className="flex flex-col w-full lg:w-1/2 gap-6">
          <h1 className="font-quicksand font-bold text-3xl text-primary">
            SCHOOL VISITS
          </h1>
          <p className="font-rubik text-[#464646]">
            First of all, I plan to use more pictures than words, the pictures
            will have to be self-explanatory. Jolly Nyiratunga - ASEF's
            Community Outreach Director talks about her experience with school
            visits. “I have been doing school visits for about 5 years now and
            it is among my favourite activities within ASEF,I enjoy the
            travels,learning a lot of things about different schools but the
            most exciting part of these visits is the joy it brings to our
            students”.
          </p>
          <Link to={'/programs/school-visits'} className="w-fit">
            <Button
              type="button"
              content={
                <div className="flex items-center justify-center px-2">
                  <p className="text-white font-rubik font-normal">READ MORE</p>
                </div>
              }
              loading={false}
              btnColor="secondary"
              Style={'text-white w-max ml-0'}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OurPrograms;
