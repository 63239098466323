import React, { useEffect } from 'react';

const Twitter = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="w-full h-full shadow-md rounded-xl">
      <a
        class="twitter-timeline"
        data-height="600"
        href="https://twitter.com/ASEFRwanda?ref_src=twsrc%5Etfw"
      >
        Tweets by ASEFRwanda
      </a>{' '}
      <script
        async
        src="https://platform.twitter.com/widgets.js"
        charset="utf-8"
      ></script>
    </div>
  );
};

export default Twitter;
