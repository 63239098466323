import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BiChevronRight } from 'react-icons/bi';
import { LuChevronDown } from 'react-icons/lu';
import WhatIsAsef from './faq_pages/WhatIsAsef';
import BeneficiarySelection from './faq_pages/BeneficiarySelection';
import AsefWithStudents from './faq_pages/AsefWithStudents';
import AsefSupport from './faq_pages/AsefSupport';
import AsefBeneficiary from './faq_pages/AsefBeneficiary';
import Collapsible from '../../components/Collapsible';
import Button from '../../components/Button';

const FAQ = [
  {
    q: 'Does ASEF pay full tuition?',
    a: [
      {
        p: 'Yes, ASEF pays full tuition as per REB and Ministry of education guidelines as appears on the parent sheet provided by the schools.',
      },
    ],
  },
  {
    q: 'Who is an ASEF beneficiary?',
    a: [
      {
        p: 'ASEF beneficiary is a promising and bright student who lack financial means to pursue secondary school education.',
      },
      {
        p: 'Though many of the beneficiaries are young, there are no specific age limits to who can be an ASEF beneficiary.',
      },
      {
        p: 'The beneficiaries must be from the mentioned 11 districts of Rwanda of ASEF activities.',
      },
    ],
  },
  {
    q: 'How are beneficiaries selected?',
    a: [
      {
        p: ' ASEF collaborates with select district and sector authorities to identify the most qualified vulnerable and bright candidates.',
      },
    ],
  },
  {
    q: 'What are the criteria of qualification?',
    a: [
      {
        p: 'ASEF considers the following students:',
      },
      {
        p: '1. Entering S1 or S4.',
      },
      {
        p: '2. Be in the top 10 of the class in previous academic year.',
      },
      {
        p: '3. Be among students with highest aggregate in their district during national examinations.',
      },
      {
        p: '4. Be coming from a family under poverty.',
      },
      {
        p: '5. Good behavior.',
      },
      {
        p: 'Selection begins prior to the start of the academic year, once National Examination results have posted The sector provides a list of candidates to apply for a scholarship. In doing so, the sector agrees to vet candidates of all ASEF criteria prior to nominating students.',
      },
      {
        p: 'ASEF makes the final selection considering students proposed by the sector leaders.',
      },
    ],
  },
  {
    q: 'What is ASEF?',
    a: [
      {
        p: "African Students' Education Fund (ASEF) is a non-profit organization that provides scholarships to vulnerable yet bright secondary school students in Rwanda.",
      },
      {
        p: 'ASEF works as an INGO under the Rwanda Governance Board (RGB) and holds 501(c)3 status in the United States.',
      },
    ],
  },
];

const FAQs = () => {
  const [expandedIndex, setExpandedIndex] = useState(0);

  const generateFaqContent = () => {
    switch (expandedIndex) {
      case 0:
        return <AsefSupport />;

      case 1:
        return <AsefBeneficiary />;

      case 2:
        return <BeneficiarySelection />;
      case 3:
        return <AsefWithStudents />;
      case 4:
        return <WhatIsAsef />;

      default:
        return <AsefSupport />;
    }
  };

  return (
    <div
      id="faq"
      className="w-full h-fit flex flex-col gap-6 lg:gap-12 lg:flex-row px-6 md:px-12 lg:px-32 py-12"
    >
      <div className="w-full lg:w-[40%]">
        <div className="flex items-center justify-center lg:justify-start gap-2 mb-12">
          <div className="bg-secondary w-[50px] lg:w-[80px] h-[4px]"></div>
          <h1 className="text-secondary font-semibold font-rubik">FAQ</h1>
          <div className="bg-secondary w-[50px] lg:w-[80px] h-[4px]"></div>
        </div>

        <h1 className="text-primary font-bold text-xl lg:text-4xl text-center lg:text-start font-quicksand mt-4">
          Get Answers to Your Questions:
        </h1>
        <h1 className="text-primary font-bold text-xl lg:text-4xl text-center lg:text-start font-quicksand mt-1 lg:mt-4">
          Explore Our FAQ Section
        </h1>

        <Link to="/faq" className="flex w-fit mx-auto lg:mx-0 mt-6">
          <Button
            type="button"
            content={
              <div className="flex items-center justify-center py-1 px-4">
                <p className="text-white font-rubik font-normal text-lg">
                  View More FAQ
                </p>
              </div>
            }
            loading={false}
            btnColor="secondary"
            Style={'text-white w-max mt-4 flex self-end'}
          />
        </Link>
      </div>
      <div className="flex flex-col items-start w-full lg:w-[60%] mt-6 lg:mt-12 mb-6">
        {FAQ.map((question, index) => {
          return (
            <div className="flex flex-col gap-6 border-b border-b-black/20 w-full py-4">
              <Collapsible
                heading={
                  <h1 className="font-rubik font-[500] text-lg ">
                    {question.q}
                  </h1>
                }
                body={
                  <div className="flex flex-col gap-3">
                    {question.a.map((answer) => {
                      return <h1>{answer.p}</h1>;
                    })}
                  </div>
                }
                Style={'w-full'}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FAQs;
