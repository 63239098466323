import React, { useState, useRef } from 'react';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { MdOutlineMail } from 'react-icons/md';
import { PiPhoneCall } from 'react-icons/pi';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import Button from '../../components/Button';
import { useForm } from 'react-hook-form';
import frame from '../../assets/frame.png';
import emailjs from '@emailjs/browser';
import axios from 'axios';
import { ToastComponent, ToasterComponent } from '../../components/Toast';

const Contact = () => {
  const form = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    control,
  } = useForm();

  const notify = (type, message) => {
    ToastComponent({
      message: message,
      type: type,
    });
  };

  const [submitLoading, setSubmitLoading] = useState(false);

  const [contactForm, setContactForm] = useState({
    from_name: '',
    from_email: '',
    phone_number: '',
    from_district: '',
    message: '',
  });

  const handleNames = (e) => {
    const { value } = e.target;
    setContactForm((prevData) => {
      return {
        ...prevData,
        from_name: value,
      };
    });
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    setContactForm((prevData) => {
      return {
        ...prevData,
        from_email: value,
      };
    });
  };

  const handlePhone = (e) => {
    const { value } = e.target;
    setContactForm((prevData) => {
      return {
        ...prevData,
        phone_number: value,
      };
    });
  };

  const handleDistrict = (e) => {
    const { value } = e.target;
    setContactForm((prevData) => {
      return {
        ...prevData,
        from_district: value,
      };
    });
  };

  const handleMessage = (e) => {
    const { value } = e.target;
    setContactForm((prevData) => {
      return {
        ...prevData,
        message: value,
      };
    });
  };

  const handleInput = () => {
    console.log('');
  };

  const onSubmit = () => {
    setSubmitLoading(true);
    axios
      .get(
        `https://api.hunter.io/v2/email-verifier?email=${contactForm.from_email}&api_key=9889e60f43a8ae0d71c2eab1c92b8b98a9cc330d`
      )
      .then((response) => {
        if (response.data.data.status == 'invalid') {
          setSubmitLoading(false);
          notify('error', 'Please enter a valid email');
        } else {
          emailjs
            .sendForm(
              'service_ieja7if',
              'template_hbxp6rl',
              form.current,
              '-y3pkN-Dnu8s1G7iN'
            )
            .then(
              (response) => {
                reset();
                setSubmitLoading(false);
                notify('success', 'Email sent successfully');
              },
              (error) => {
                setSubmitLoading(false);
                notify('error', error.response.data.message);
                console.log(error, 'there was an error');
              }
            );
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        notify('error', error.response.data.message);
        console.log(error);
      });
  };

  return (
    <div
      id="contact"
      className="w-full py-12 px-6 md:px-12 lg:px-32 flex flex-col gap-12"
    >
      <div
        className="bg-cover bg-center flex flex-col lg:flex-row items-center justify-between font-rubik max-h-[250px] lg:h-[180px] w-full px-6 lg:px-12 py-6 rounded-2xl"
        style={{ backgroundImage: `url(${frame})` }}
      >
        <div className="flex flex-col">
          <div className="flex flex-col lg:flex-row items-center gap-2">
            <div className="bg-secondary w-[60px] lg:w-[80px] h-[4px]"></div>
            <p className="text-center lg:text-start text-secondary text-lg font-quicksand">
              NEWSLETTER
            </p>
          </div>
          <p className="w-full text-white font-rubik text-3xl">Subscribe Now</p>
        </div>
        <div className="w-full lg:w-1/2">
          <div className="flex flex-col lg:flex-row items-start w-full gap-6 lg:gap-0">
            {' '}
            <Input
              labelFor={'email_address'}
              name={'email_address'}
              type={'text'}
              isRequired={false}
              placeholder={'Enter Your Email Address'}
              onChange={(e) => {
                handleInput(e);
              }}
              inputStyle="w-full h-[46px] lg:rounded-none lg:rounded-l-lg"
              customClass={'w-full lg:w-2/3'}
              register={register}
              errors={errors}
            />
            <Button
              type="submit"
              content={
                <div className="flex items-center justify-center py-[3px]">
                  <p className="text-white font-rubik font-medium">
                    Subscribe Now
                  </p>
                </div>
              }
              loading={false}
              btnColor="secondary"
              Style={
                'text-white w-full lg:w-[200px] lg:rounded-none lg:rounded-r-lg mt-[4px]'
              }
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row justify-between gap-6">
        <div className="bg-darkGrey flex flex-col py-6 px-6 lg:px-12 gap-6 rounded-xl h-fit lg:h-full ">
          <div className="w-fit mx-auto lg:mx-0 flex flex-col lg:flex-row items-center gap-4 mb-2">
            <div className="bg-secondary h-[4px] w-[50px] lg:w-[80px]"></div>
            <h1 className="text-secondary font-semibold font-rubik">
              CONTACT US
            </h1>
          </div>

          <div className="flex flex-col gap-6 w-full">
            <div className="flex justify-center lg:justify-start items-start gap-2 w-fit">
              <div className="bg-primary py-2 px-4 rounded-md flex items-center">
                <HiOutlineLocationMarker size={20} color="#ffffff" />
              </div>
              <div className="flex flex-col gap-1 w-fit">
                <h4 className="font-quicksand font-bold w-fit">Our office</h4>
                <p className="font-rubik text-fontGrey lg:w-3/5">
                  La Bonne Adresse Building, 3rd Floor KN 4 Roundabout
                </p>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row w-full gap-6 justify-between items-start lg:items-center">
              <div className="flex items-start gap-2">
                <div className="bg-primary py-2 px-4 rounded-md">
                  <MdOutlineMail size={20} color="#ffffff" />
                </div>
                <div className="flex flex-col gap-1">
                  <h4 className="font-quicksand font-bold">Email</h4>
                  <p className="font-rubik text-fontGrey lg:w-3/5">
                    asef@asefrwanda.org
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-2">
                <div className="bg-primary py-2 px-4 rounded-md">
                  <PiPhoneCall size={20} color="#ffffff" />
                </div>
                <div className="flex flex-col gap-1">
                  <h4 className="font-quicksand font-bold">Phone Number</h4>
                  <p className="font-rubik text-fontGrey lg:w-3/5">2733</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.5172629576787!2d30.058391510616314!3d-1.9460126980281431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dca42155a5db79%3A0xa11d75e9630125f6!2sAfrican%20Students%20Education%20Fund%20(ASEF)!5e0!3m2!1sen!2srw!4v1707828075979!5m2!1sen!2srw"
              height="150"
              style={{ border: '0' }}
              allowFullScreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              className="w-full"
            ></iframe>
          </div>
        </div>

        <div className="flex flex-col lg:w-1/2 mt-4 lg:mt-0">
          <h1 className="w-fit mx-auto lg:mx-0 lg:w-4/5 text-primary font-bold text-2xl lg:text-4xl font-quicksand">
            Leave Us Message
          </h1>

          <form
            ref={form}
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-3 mt-6"
          >
            <div className="flex flex-col lg:flex-row items-center gap-3">
              <Input
                labelText={'Full Name'}
                labelFor={'from_name'}
                name={'from_name'}
                type={'text'}
                isRequired={true}
                placeholder={'Enter Full Name'}
                onChange={(e) => {
                  handleNames(e);
                }}
                customClass="w-full"
                inputStyle={'h-[45px]'}
                register={register}
                errors={errors}
              />

              <Input
                labelText={'Email'}
                labelFor={'from_email'}
                name={'from_email'}
                type={'email'}
                isRequired={true}
                placeholder={'Enter Your Email'}
                onChange={(e) => {
                  handleEmail(e);
                }}
                customClass="w-full"
                inputStyle={'h-[45px]'}
                register={register}
                errors={errors}
              />
            </div>
            <div className="flex flex-col lg:flex-row items-center gap-3">
              <Input
                labelText={'Phone Number'}
                labelFor={'phone_number'}
                name={'phone_number'}
                type={'number'}
                isRequired={true}
                placeholder={'Enter Your Phone Number'}
                onChange={(e) => {
                  handlePhone(e);
                }}
                customClass="w-full"
                inputStyle={'h-[45px] no-spinner'}
                register={register}
                errors={errors}
              />

              <Input
                labelText={'District'}
                labelFor={'from_district'}
                name={'from_district'}
                type={'text'}
                isRequired={true}
                placeholder={'Enter Your District'}
                onChange={(e) => {
                  handleDistrict(e);
                }}
                customClass="w-full"
                inputStyle={'h-[45px]'}
                register={register}
                errors={errors}
              />
            </div>

            <TextArea
              labelText={'Your Message'}
              labelFor={'message'}
              name={'message'}
              type={'text'}
              isRequired={true}
              placeholder={'Enter Your Message'}
              onChange={(e) => {
                handleMessage(e);
              }}
              customClass="w-full"
              inputStyle={'h-[150px]'}
              register={register}
              errors={errors}
            />

            <Button
              type="submit"
              content={
                <div className="flex items-center justify-center px-4 py-1">
                  <p className="text-white font-rubik font-medium ">
                    Submit Message
                  </p>
                </div>
              }
              loading={submitLoading}
              btnColor="primary"
              Style={'text-white w-full lg:w-fit mt-8'}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
