import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import Carousel from '../../components/Carousel';
import Story from '../../assets/story.png';
import { BiSolidQuoteAltLeft } from 'react-icons/bi';
import { LuMoveRight } from 'react-icons/lu';
import elie from '../../assets/elie.png';
import ghad from '../../assets/ghad.png';
import emmanuel from '../../assets/emmanuel.png';

const stories = [
  {
    id: 1,
    name: 'Ntakirutimana Elie',
    role: 'Student at cornell  University',
    location: 'USA',
    image: elie,
    message:
      'I am known for my unwavering spirit, empathy, and resilience. In the face of adversity, I love demonstrating unshakable determination and a relentless pursuit of self-improvement. My defining trait is my deep desire to make a difference,with dreams rooted in altruism and a commitment to transforming the lives of the vulnerable.',
  },
  {
    id: 2,
    name: 'Nkurunziza Ghad',
    role: 'Student at Wesleyan University',
    location: 'USA',
    image: ghad,
    message:
      'Growing up as the firstborn, I grew up in a low-income family of two parents and four siblings in the rural area part of Rwanda, Burera-Butaro. Looking at myself, risk-taking, persistence and a growth mindset define the truest essence of my personality',
  },
  {
    id: 3,
    name: 'Nizeyimana Emmanuel',
    role: 'Student at Gahini GS',
    location: 'RWANDA',
    image: emmanuel,
    message:
      "Njyewe natangiye kwiga ntinze bitewe n'imyumvire ndetse n'ubushobozi bw'umuryango nkomokamo. Njyeze mu mwaka wa gatandatu w'amashuri abanza nafataga umwanya nkajya ku muhanda mugihe abanyeshuri biga mu mashuri yisumbuye babaga bahetse ibikapu bagiye",
  },
];

const BriefStory = ({ setChosenStory }) => {
  return (
    <div
      id="brief_story"
      className="bg-darkGrey w-full py-12 px-8 lg:pl-32 flex justify-center items-center"
    >
      <div className="w-full flex flex-col lg:flex-row items-center gap-4">
        <div className="w-full lg:w-1/4 flex flex-col gap-4 p-2">
          <div className="w-fit mx-auto lg:mx-0 flex flex-col lg:flex-row  items-center gap-2">
            <div className="bg-secondary w-[50px] lg:w-[80px] h-[4px]"></div>
            <h1 className="text-secondary font-semibold font-rubik">STORIES</h1>
          </div>
          <h1 className="text-primary font-bold text-lg lg:text-3xl text-center lg:text-start">
            We share our student success stories
          </h1>

          <Link to="/stories" className="w-fit mx-auto lg:mx-0">
            <Button
              type="button"
              content={
                <div className="flex items-center justify-center py-1 px-4">
                  <p className="text-white font-rubik font-normal">
                    View More Stories
                  </p>
                </div>
              }
              loading={false}
              btnColor="secondary"
              Style={'text-white w-max ml-0 flex self-start'}
            />
          </Link>
        </div>
        <div className="w-full lg:w-3/4">
          <div className="w-[99%] mx-auto">
            <Carousel
              slidesToShow={2}
              slidesToScroll={1}
              style={'w-full flex justify-center items-center mx-auto'}
              arrows={true}
              pauseOnHover={true}
            >
              {stories.map((narrator, i) => {
                return (
                  <div
                    key={i}
                    className="bg-white flex flex-row p-4 mx-5 rounded-2xl max-w-[90%] lg:max-w-[400px] h-[300px] lg:h-[250px] self-center w-fit"
                  >
                    <div className="w-full flex items-start gap-3">
                      <div className="bg-cover w-[50px] h-[50px] rounded-2xl overflow-hidden">
                        <img
                          src={narrator.image}
                          alt=""
                          className="object-cover w-full h-full"
                        />
                      </div>

                      <div className="flex flex-col gap-2">
                        <h2 className="font-bold font-quicksand text-center lg:text-start">
                          {narrator.name}
                        </h2>
                        <p className="font-rubik text-sm text-fontGrey text-center lg:text-start">
                          {narrator.role}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col gap-2">
                      <div className="flex flex-col gap-2">
                        <BiSolidQuoteAltLeft
                          size={20}
                          color="#FDB935"
                          className="mx-auto lg:mx-0"
                        />
                        <p>
                          {narrator.message.length > 150
                            ? narrator.message.slice(0, 150) + '...'
                            : narrator.message}
                        </p>
                      </div>

                      <Link
                        to={'/stories/single'}
                        className="w-fit"
                        onClick={() => {
                          setChosenStory(narrator.id);
                        }}
                      >
                        <div className="flex gap-2 items-center w-fit cursor-pointer">
                          <p className="text-secondary font-rubik">Read more</p>
                          <LuMoveRight size={20} color="#FDB935" />
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BriefStory;
