import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { InstagramEmbed } from 'react-social-media-embed';

const Instagram = () => {
  const [recentIgPosts, setRecentIgPosts] = useState(new Set());

  useEffect(() => {
    axios
      .get(`https://graph.facebook.com/v19.0/17841407761416041/media`, {
        params: {
          access_token:
            'EAAFDbkKqZCQcBO8kTmCFbdmJdZCsfk5iTuYYCZBkQeeT23iis2KlOSBlssXNUmAlFs0lZB6992RYCQaoPuqXWJzaG0jPS9eZA9yOAk8J6wa0av8bQb2pwJZAkZBpTBAUO5sVMmlIEfWz1kz9BRKXTqAWTgHIrVkZA9bOfFnPX8aGBi5PuO7gDRstZCxxm',
          debug: 'all',
          format: 'json',
          limit: 6,
          method: 'get',
          origin_graph_explorer: 1,
          pretty: 0,
          suppress_http_code: 1,
          transport: 'cors',
        },
      })
      .then((response) => {
        const promises = response.data.data.map((object) => {
          return axios
            .get(`https://graph.facebook.com/v19.0/${object.id}`, {
              params: {
                fields: 'permalink',
                access_token:
                  'EAAFDbkKqZCQcBO8kTmCFbdmJdZCsfk5iTuYYCZBkQeeT23iis2KlOSBlssXNUmAlFs0lZB6992RYCQaoPuqXWJzaG0jPS9eZA9yOAk8J6wa0av8bQb2pwJZAkZBpTBAUO5sVMmlIEfWz1kz9BRKXTqAWTgHIrVkZA9bOfFnPX8aGBi5PuO7gDRstZCxxm',
                debug: 'all',
                format: 'json',
                method: 'get',
                origin_graph_explorer: 1,
                pretty: 0,
                suppress_http_code: 1,
                transport: 'cors',
              },
            })
            .then((response) => {
              setRecentIgPosts(
                (prevData) => new Set(prevData.add(response.data.permalink))
              );
            })
            .catch((error) => {
              console.log(error, 'error');
            });
        });

        // Wait for all promises to resolve
        Promise.all(promises).then(() => {
          console.log('All posts fetched');
        });
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  }, []);

  useEffect(() => {
    // Load Instagram embed script asynchronously
    const scriptInstagram = document.createElement('script');
    scriptInstagram.src = '//www.instagram.com/embed.js';
    scriptInstagram.async = true;
    document.body.appendChild(scriptInstagram);

    return () => {
      document.body.removeChild(scriptInstagram);
    };
  }, []);

  return (
    <div className="w-full flex flex-wrap gap-6 items-center justify-center lg:items-start h-fit overflow-x-hidden">
      {Array.from(recentIgPosts).map((link) => {
        return (
          <div className="max-w-[350px] !overflow-x-hidden ">
            <InstagramEmbed url={link} width={350} />
          </div>
        );
      })}
    </div>
  );
};

export default Instagram;
