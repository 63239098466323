import React, { useState, useEffect } from 'react';
import Home from './home/Home';
import BriefAbout from './about/BriefAbout';
import Sponsorship from './sponsorship/Sponsorship';
import BriefPrograms from './programs/BriefPrograms';
import BriefNews from './news/BriefNews';
import SocialMedia from './social/SocialMedia';
import BriefStory from './stories/BriefStory';
import FAQs from './faqs/FAQs';
import Partners from './partners/Partners';
import Contact from './contact/Contact';
import Footer from '../components/Footer';

const LandingPage = ({ setChosenNews, setChosenStory }) => {
  return (
    <div className="w-full h-full">
      <Home />
      <BriefAbout />
      <Sponsorship />
      <BriefPrograms />
      <BriefNews setChosenNews={setChosenNews} />
      <SocialMedia />
      <BriefStory setChosenStory={setChosenStory} />
      <FAQs />
      <Partners />
      <Contact />
      <Footer top="landing_home" />
    </div>
  );
};

export default LandingPage;
