import React from 'react';
import logo from '../assets/logo.png';
import { FaFacebook, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

import { FaArrowUp } from 'react-icons/fa6';
import Button from './Button';

const Footer = ({ top }) => {
  function getCurrentYear() {
    return new Date().getFullYear();
  }
  const currentYear = getCurrentYear();
  return (
    <div className="flex flex-col w-full">
      <div className="relative bg-primary flex flex-col lg:flex-row justify-between px-12 md:px-32 py-12 gap-12 items-start w-full">
        <div className="absolute flex flex-col items-center top-[-23px] right-[10px]">
          <a href="#top_bar">
            <Button
              type="button"
              content={
                <div className="flex items-center justify-center px-2">
                  <FaArrowUp color="#ffffff" size={20} />
                </div>
              }
              loading={false}
              btnColor="secondary"
              padding="px-1 py-3"
              Style={'text-white w-max rounded-md'}
            />
          </a>

          <p className="font-rubik text-white mt-2">Back to Top</p>
        </div>
        <div className="flex flex-col gap-3 lg:w-[40%] mx-auto lg:mx-0">
          <div className="h-[75px] w-[94px] object-contain mx-auto lg:mx-0">
            <img src={logo} alt="ASEF_LOGO" srcset="" />
          </div>

          <p className="font-rubik text-white text-center lg:text-start text-lg flex item-center">
            Education is{' '}
            <div className="flex items-center ml-1">
              <p className="italic text-secondary">Fun</p>damental.
            </div>
          </p>

          <div className="w-fit mx-auto lg:mx-0 flex items-center gap-4">
            <div className="bg-white rounded-full p-[6px] cursor-pointer">
              <FaInstagram size={15} color="#01A653" />
            </div>

            <div className="bg-white rounded-full p-[6px] cursor-pointer">
              <FaFacebook size={15} color="#01A653" />
            </div>

            <div className="bg-white rounded-full p-[6px] cursor-pointer">
              <FaLinkedinIn size={15} color="#01A653" />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-3 w-full lg:w-[20%]">
          <h1 className="font-quicksand text-secondary font-bold text-center lg:text-start">
            {' '}
            Quick Links
          </h1>

          <div className="flex w-full justify-between lg:justify-start items-center gap-6 font-rubik text-white">
            <div className="flex flex-col gap-3">
              <p className="">Home</p>
              <p className="">Our Team</p>
              <p className="">Our Founder</p>
            </div>
            <div className="flex flex-col gap-3">
              <p className="">About Us</p>
              <p className="">Contact Us</p>
              <p className="">News & Updates</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-3 w-full lg:w-[20%]">
          <h1 className="font-quicksand text-secondary font-bold text-center lg:text-start">
            {' '}
            Our Programs
          </h1>

          <div className="flex w-full justify-between lg:justify-start items-start gap-6 font-rubik text-white">
            <div className="flex flex-col gap-3">
              <p className="">New Intake</p>
              <p className="">Supply distribution</p>
              <p className="">Graduate Meetings</p>
            </div>
            <div className="flex flex-col gap-3">
              <p className="">Advisory Meetings</p>
              <p className="">School Visits</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex item-center justify-center bg-primary border-t border-white/60 px-12 py-2 w-full">
        <p className="text-center text-white font-rubik">
          @Copyrights Asef {currentYear} All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
