import React from 'react';
// import landing from '../../assets/landing.png';
import landing from '.././../assets/landing.png';
import landing2 from '../../assets/landing2.png';
import landing3 from '../../assets/landing3.png';
import Button from '../../components/Button';
import Carousel from '../../components/Carousel';

const landing_images = [
  {
    id: 1,
    image: landing,
  },
  {
    id: 2,
    image: landing2,
  },
  {
    id: 3,
    image: landing3,
  },
];
const Home = () => {
  return (
    <div id="landing_home" className="w-full h-fit relative">
      <Carousel
        slidesToShow={1}
        slidesToScroll={1}
        style={'w-full mx-auto'}
        arrows={false}
        pauseOnHover={false}
      >
        {landing_images.map((home) => {
          return (
            <div
              className="bg-cover w-full bg-center flex items-center justify-center md:justify-start h-calcVhMob lg:h-calcVh font-rubik"
              style={{ backgroundImage: `url(${home.image})` }}
            >
              <div className="w-full min-h-calcVhMob lg:min-h-calcVh">
                <img
                  src={home.image}
                  alt=""
                  className="object-cover min-h-calcVhMob lg:min-h-calcVh"
                />
              </div>
            </div>
          );
        })}
      </Carousel>

      <div className="absolute mt-0 md:mt-12 top-1/2 md:left-[10%] transform -translate-y-1/2  flex flex-col gap-4 px-6 md:px-0 md:w-[40%] xlg:w-1/3">
        <div className="flex items-center justify-center w-full gap-2">
          <div className="bg-secondary w-[60px] lg:w-[100px] h-[4px]"></div>
          <h1 className="font-semibold text-secondary w-2/3 text-lg lg:text-xl mx-2 text-center">
            African Students' Education Fund
          </h1>
          <div className="bg-secondary w-[60px] lg:w-[100px] h-[4px]"></div>
        </div>
        <p className="text-secondary font-bold text-2xl lg:text-[44px] text-center md:text-start  leading-normal font-quicksand">
          Make A Better Life Through Education{' '}
        </p>

        <p className="text-white text-lg lg:text-2xl text-start  font-rubik mt-4">
          ASEF provides scholarships to vulnerable yet bright secondary school
          students in Rwanda.
        </p>

        <div className="flex flex-col md:flex-row items-center justify-center md:justify-start gap-6 lg:gap-12 mt-4">
          <a href="#about">
            <Button
              type="button"
              content={
                <div className="flex items-center justify-center px-4 py-1">
                  <p className="text-white font-rubik font-normal text-base md:text-lg">
                    About Us
                  </p>
                </div>
              }
              loading={false}
              btnColor="primary"
              Style={'text-white w-[180px]'}
            />
          </a>
          <a href="#brief_programs">
            <Button
              type="button"
              content={
                <div className="flex items-center justify-center px-4 py-1">
                  <p className="text-white font-rubik font-normal text-base md:text-lg">
                    Our Programs
                  </p>
                </div>
              }
              loading={false}
              btnColor="secondary"
              Style={'text-white w-[180px]'}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
