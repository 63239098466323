import React from 'react';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';
import { LuMoveRight } from 'react-icons/lu';
import frame from '../../assets/frame.png';
import isomo from '../../assets/isomo.png';
import AC from '../../assets/AC.png';
import Partners from '../partners/Partners';
import Footer from '../../components/Footer';
import c2k from '../../assets/c2k.png';

const dummyInitiatives = [
  {
    id: 1,
    title: 'Coming to Kigali (C2K)',
    description:
      'It was an activity that took place from 2014 to 2018, where all students who got the first position, annually, in their classes were awarded a trip to Kigali. For most of them, it was their first time to coming Kigali.',
    date: 'Feb 21,2024',
    image: c2k,
  },

  {
    id: 2,
    title: 'Annual Camping (AC)',
    description:
      'In December 2018, more than 100 students did the interview. Among them, 35 were selected to do the camp for one week in April 2019. In July 2019 ASEF met them near their districts to follow up with the lessons they learned in April. In December 2019, ASEF conducted group interviews that had a specialty of how to be good storytellers.',
    date: 'Feb 21,2024',
    image: AC,
  },
];
const AllInitiatives = ({ setChosenInitiative }) => {
  return (
    <div className="w-full">
      <div className="w-full flex flex-col px-6 md:px-12 py-6 lg:px-32 lg:py-12">
        <div
          className="bg-cover bg-center flex flex-col lg:flex-row items-center justify-between font-rubik max-h-[250px] lg:h-[150px] w-full px-12 py-6 rounded-2xl"
          style={{ backgroundImage: `url(${frame})` }}
        >
          <div className="flex flex-col gap-1">
            <h1 className="text-secondary font-bold text-2xl lg:text-4xl font-quicksand">
              Initiatives
            </h1>
          </div>
          <p className=" w-full text-center lg:text-start lg:w-[40%]  text-white font-rubik text-base lg:text-lg">
            Discover initiatives that make a difference: get involved with our
            organisation's initiatives.
          </p>
        </div>

        <div className="my-6">
          <h1 className="font-nunito lg:text-lg text-fontGrey">
            In every activity, ASEF wants its students to have an intellectually
            challenging atmosphere, to be driven to succeed, into Rwanda's
            future professionals and leaders, and engage their curiosity and
            encourage them to be their academic and personal best, every day. In
            that context, ASEF has been attempting different special programs to
            help top-performing students open their minds. Here we can say
            Coming to Kigali and Annual Camping.
          </h1>
        </div>

        <h1 className="text-2xl font-quicksand text-primary font-bold my-6">
          Recent Initiatives
        </h1>

        <div className="w-full flex flex-col lg:flex-row gap-4">
          {dummyInitiatives.map((initiative) => {
            return (
              <div className="bg-grey rounded-2xl gap-4 flex items-start w-full lg:w-1/2 p-4">
                <div className="max-w-[200px] h-[120px] bg-cover rounded-2xl overflow-hidden">
                  <img
                    src={initiative.image}
                    alt=""
                    className="w-full h-full bg-cover"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <p className="text-fontGrey text-sm">{initiative.date}</p>
                  <p className="font-quicksand font-bold text-2xl">
                    {initiative.title.length > 30
                      ? initiative.title.slice(0, 30) + '...'
                      : initiative.title}
                  </p>

                  <p className="font-rubik text-fontGrey">
                    {initiative.description.length > 80
                      ? initiative.description.slice(0, 80) + '...'
                      : initiative.description}
                  </p>

                  <Link to={'/initiatives/single'} className="w-fit">
                    <div
                      className="flex gap-2 items-center w-fit cursor-pointer"
                      onClick={() => {
                        setChosenInitiative(initiative.id);
                      }}
                    >
                      <p className="text-secondary font-rubik">Read more</p>
                      <LuMoveRight size={20} color="#FDB935" />
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Partners />
      <Footer />
    </div>
  );
};

export default AllInitiatives;
