import React from 'react';
import { Link } from 'react-router-dom';
import { LuMoveRight } from 'react-icons/lu';
import Button from '../../components/Button';
import adv_meeting from '../../assets/adv_meeting.jpeg';
import school_visit from '../../assets/school_visit.png';
import oct_intake from '../../assets/oct_intake.png';
import nov_agm from '../../assets/nov_agm.jpg';

const dummyBriefNews = [
  {
    id: 1,
    category: 'NEWS',
    image: adv_meeting,
    date: 'Feb 14, 2024',
    title: '2023 December  Advisory meetings',
    description:
      'It was the First time to conduct meetings in English and most of the students were shy but left the meeting place positively and ready to practice English at school to be able to interact with ASEF staff and interns in April 2024.',
  },
  {
    id: 2,
    category: 'NEWS',
    image: school_visit,
    date: 'Feb 14, 2024',
    title: '2023 November School visits',
    description:
      'In total, 174 students were visited in 20 schools from November 07th to 23rd. The priority was new schools and those not visited in last academic year. We were happy to visit new schools like International Technical School of Kigali, MIPC, and Nyanza Hills Academy. Before using English in Advisory meetings, ASEF introduced it first in school visits. Students enjoyed the new challenge and said that they will be using English while talking to each other.',
  },
  {
    id: 3,
    category: 'NEWS',
    image: oct_intake,
    date: 'Feb 14, 2024',
    title: '2023 October New intake',
    description:
      '330 students entered the program - It is the first time to take such a large number of new students.',
  },
  {
    id: 4,
    category: 'NEWS',
    image: nov_agm,
    date: 'Feb 14, 2024',
    title: '2023 November AGM',
    description:
      'Kigali, 150 graduates attended ,Musanze , 87 graduates attended, Burera, 20 graduates attended,Nyanza, 52 graduates attended.',
  },
];

const BriefNews = ({ setChosenNews }) => {
  return (
    <div
      id="brief_news"
      className="w-full h-fit px-6 md:px-12 lg:px-32 py-16 bg-darkGrey"
    >
      <div className="flex flex-col lg:flex-row items-center gap-2 justify-start">
        <div className="bg-secondary w-[30px] lg:w-[80px] h-[4px]"></div>
        <h1 className="text-secondary font-semibold font-rubik">NEWS</h1>
      </div>
      <h1 className="mt-4 text-primary font-bold text-xl lg:text-4xl text-center lg:text-start">
        Latest News and Updates
      </h1>

      <div className="flex flex-col">
        <div className="flex flex-col items-center lg:items-start lg:flex-row gap-12 mt-12">
          {dummyBriefNews?.map((news, i) => {
            return (
              <div
                key={i}
                className="flex flex-col px-2 lg:px-4 py-3 lg:py-6 max-w-[500px] lg:w-[350px]"
              >
                <div className="bg-cover overflow-hidden rounded-xl  max-w-[480px] lg:max-w-[320px] max-h-[200px]">
                  <img
                    src={news.image}
                    alt=""
                    className="bg-cover w-full h-full"
                  />
                </div>
                <div className="flex items-center gap-4 my-2 ">
                  <p className="font-rubik font-bold text-secondary text-sm">
                    {news.category}
                  </p>
                  <p className="text-fontGrey text-sm lg:text-base">
                    {news.date}
                  </p>
                </div>
                <h1 className="font-bold text-lg lg:text-lg">
                  {news.title.length > 40
                    ? news.title.slice(0, 40) + '...'
                    : news.title}
                </h1>
                <p className="my-3 text-sm lg:text-base">
                  {' '}
                  {news.description.length > 150
                    ? news.description.slice(0, 150) + '...'
                    : news.description}
                </p>

                <Link to={'/news/single'} className="w-fit">
                  <div
                    className="flex gap-2 items-center w-fit cursor-pointer"
                    onClick={() => {
                      setChosenNews(news.id);
                    }}
                  >
                    <p className="text-secondary font-rubik">Read more</p>
                    <LuMoveRight size={20} color="#FDB935" />
                  </div>
                </Link>
              </div>
            );
          })}
        </div>

        <div className="flex w-full justify-center lg:justify-end mt-6">
          <Link to="/news" className="w-fit">
            <Button
              type="button"
              content={
                <div className="flex items-center justify-center px-4 py-1">
                  <p className="text-white font-rubik font-normal">View More</p>
                </div>
              }
              loading={false}
              btnColor="secondary"
              Style={'text-white w-max ml-0 flex self-end'}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BriefNews;
