import React from 'react';

const Video = () => {
  return (
    <div className="bg-darkGrey w-full h-fit lg:h-full px-12 py-6 lg:px-32 lg:py-12 flex items-center justify-center">
      <div className="w-fit rounded-lg lg:rounded-3xl overflow-auto">
        <iframe
          // width="560"
          // height="315"
          src="https://www.youtube.com/embed/zEQTLpC-EVw?si=eVgXtWZVd-0SvBZP"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="w-[350px] md:w-[600px] lg:w-[1000px] aspect-video my-auto"
        ></iframe>
      </div>
    </div>
  );
};

export default Video;
