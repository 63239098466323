import React, { Suspense, useState, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopBar from './components/TopBar';
import MainRoutes from './Routes/mainRoutes';
import Loader from './components/Loader';
import { ToasterComponent } from './components/Toast';
import AllOpportunities from './pages/opportunities/AllOpportunities';
import Opportunity from './pages/opportunities/Opportunity';
import GraduateMeetings from './pages/programs/detail/programs_pages/GraduateMeetings';
import Initiative from './pages/initiatives/Initiative';
import AllInitiatives from './pages/initiatives/AllInitiatives';
import Story from './pages/stories/detail/Story';
const About = lazy(() => import('./pages/about/detail/About'));
const Programs = lazy(() => import('./pages/programs/detail/Programs'));
const News = lazy(() => import('./pages/news/detail/News'));
const NewsPage = lazy(() => import('./pages/news/detail/news_pages/NewsPage'));
const Stories = lazy(() => import('./pages/stories/detail/Stories'));
const NewsLetter = lazy(() => import('./pages/newsletter/NewsLetter'));
const NewIntake = lazy(() =>
  import('./pages/programs/detail/programs_pages/NewIntake')
);
const AdvisoryMeetings = lazy(() =>
  import('./pages/programs/detail/programs_pages/AdvisoryMeetings')
);
const AnnualCamping = lazy(() =>
  import('./pages/programs/detail/programs_pages/AnnualCamping')
);

const Jadf = lazy(() => import('./pages/programs/detail/programs_pages/Jadf'));

const Supplies = lazy(() =>
  import('./pages/programs/detail/programs_pages/Supplies')
);

const SchoolVisits = lazy(() =>
  import('./pages/programs/detail/programs_pages/SchoolVisits')
);
const FAQPage = lazy(() => import('./pages/faqs/FAQPage'));

const App = () => {
  const [chosenNews, setChosenNews] = useState(1);
  const [chosenOpportunity, setChosenOpportunity] = useState(1);
  const [chosenInitiative, setChosenInitiative] = useState(1);
  const [chosenStory, setChosenStory] = useState(1);
  const [navigateToSection, setNavigateToSection] = useState('');
  return (
    <Router>
      <ToasterComponent />
      <Suspense fallback={<Loader />}>
        <TopBar setNavigateToSection={setNavigateToSection} />
        <Routes>
          <Route
            path="*"
            element={
              <MainRoutes
                setChosenNews={setChosenNews}
                setChosenStory={setChosenStory}
              />
            }
          />
          <Route
            path={`/about-us`}
            element={
              <About
                navigateToSection={navigateToSection}
                setNavigateToSection={setNavigateToSection}
              />
            }
          />
          <Route
            path={`/news/single`}
            element={
              <NewsPage chosenNews={chosenNews} setChosenNews={setChosenNews} />
            }
          />
          <Route path={`/newsletter`} element={<NewsLetter />} />
          <Route
            path={`/programs`}
            element={
              <Programs
                navigateToSection={navigateToSection}
                setNavigateToSection={setNavigateToSection}
              />
            }
          />
          <Route path={`/programs/new-intake`} element={<NewIntake />} />
          <Route
            path={`/programs/advisory-meetings`}
            element={<AdvisoryMeetings />}
          />
          <Route
            path={`/programs/annual-camping`}
            element={<AnnualCamping />}
          />

          <Route path={`/programs/jadf`} element={<Jadf />} />
          <Route path={`/programs/supplies`} element={<Supplies />} />
          <Route path={`/programs/school-visits`} element={<SchoolVisits />} />

          <Route
            path={`/programs/graduate-meetings`}
            element={<GraduateMeetings />}
          />

          <Route
            path={`/opportunities`}
            element={
              <AllOpportunities setChosenOpportunity={setChosenOpportunity} />
            }
          />

          <Route
            path={`/opportunities/single`}
            element={
              <Opportunity
                chosenOpportunity={chosenOpportunity}
                setChosenOpportunity={setChosenOpportunity}
              />
            }
          />

          <Route
            path={`/initiatives`}
            element={
              <AllInitiatives setChosenInitiative={setChosenInitiative} />
            }
          />

          <Route
            path={`/initiatives/single`}
            element={
              <Initiative
                chosenInitiative={chosenInitiative}
                setChosenInitiative={setChosenInitiative}
              />
            }
          />

          <Route
            path={`/news`}
            element={<News setChosenNews={setChosenNews} />}
          />

          <Route
            path={`/stories`}
            element={<Stories setChosenStory={setChosenStory} />}
          />
          <Route
            path={`/stories/single`}
            element={
              <Story
                chosenStory={chosenStory}
                setChosenStory={setChosenStory}
              />
            }
          />
          <Route path={`/faq`} element={<FAQPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
